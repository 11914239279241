import React from 'react'
import styled from 'styled-components'
import { Button, Heading, Flex } from '@pancakeswap/uikit'
import Page from 'components/Layout/Page'
import { useTranslation } from '@pancakeswap/localization'

const Container = styled.div`
  width: 100%;
  max-width: 1300px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  flex-direction: column;
`
const NotFound = () => {
  const { t } = useTranslation()

  return (
    <Page>
      <Container>
        <Flex flexDirection="column" width="100%" justifyContent="center" alignItems="center">
          <CsHeaderMaster scale="xxl">We are under maintenance</CsHeaderMaster>
          <CsHeader scale="xxl">We will be back soon</CsHeader>
        </Flex>
        <ContainerImg />
      </Container>
    </Page>
  )
}

export default NotFound

const ContainerImg = styled(Flex)`
  width: 50%;
  height: 473.03px;
  background-image: url('/images/backgourd_undeffine.png');
  background-size: 100%;
  background-repeat: no-repeat;
  margin-top: 30px;
  @media only screen and (min-width: 768px) and (max-width: 1000px) {
    height: 400px;
  }
  @media only screen and (max-width: 600px) {
    margin-top: 60px;
    width: 100%;
    height: 473.03px;
  }
`
const CsHeader = styled(Heading)`
  width: 625px;
  height: 16px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 16px;
  text-align: center;
  color: #777e91;
  margin-top: 30px;
`
const CsHeaderMaster = styled(Heading)`
  @media only screen and (max-width: 600px) {
    font-size: 38px;
    text-align: center;
  }
`
